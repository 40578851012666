header {
//   border-bottom: 0.1px solid white;
}
.searchbar {
  margin-right: 40%;
  margin-left: 2%;
  display: flex;
  font-size: 1.1em;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 75px;
  img {
    width: 15px;
  }
}
.h-logo {
  margin-right: auto;
}
.h-logo > img {
  width: 70px;
}
.menuIcon {
  font-size: 2em;
}
.floating-searchbar {
  position: absolute;
  z-index: 100;
  left: 30%;
  top: 6%;
  padding: 20px;
  background: black;
  max-height: 75%;
  height: 50%;
  transition: all 2s ease-in-out;
}
.smaller {
  font-size: 0.8em !important;
}
#dropdown-menu-align-end{
    color: white;
    font-weight: bold;
    background-color: black;
    margin-left: 10%;
    border: black;
}

@media only screen and (max-width: 1200px) {
  .w-35 {
    width: auto;
  }
  .smaller {
    font-size: .4em !important;
  }
}
