input:focus{
    outline:none;
}
input{
    width:90%;
}
.search{
    border: 1px solid var(--primary);
    width:100%;
    padding:10px;
    transition: all 1s ease;
    margin:0;
}