.xP{
  padding: 0 6%;
}
.vspacer{
  height:100px;
}


@media only screen and (max-width: 576px){
  
}

@media only screen and (max-width: 768px){
  
}

@media only screen and (max-width: 1200px){
  
}

@media only screen and (max-width: 1400px){
  
}