.big-header{
    font-size:3.5rem;
}
.w-35{
    width:30%;
}
.no-style{
text-decoration:none;
}
.click{
    cursor:pointer;
}
.heroCover{
    object-fit: cover;
    height: 50vh;
    width: 100vw;
    object-position: center;
}

@media only screen and (max-width: 576px){
.w-35{
    width:auto;
}
}