.twitterIcon,
.facebookIcon,
.youtubeIcon {
  font-size: .8em;
}
.twitterIcon {
  color: #1da1f2;
}
.facebookIcon {
  color: #4267b2;
}
.youtubeIcon {
  color: red;
}
.logo {
    font-size: .8em;
  img {
    width: 30px;
  }
}
.small{
    font-size: .8em;
}