:root{
  --primary: #131C47;
  --danger: #FF0606;
  --secondary: #101057;
}
$theme-colors: (
  "primary": #131C47,
  "danger": #FF0606,
  "secondary": #101057,
);
@import '~bootstrap/scss/bootstrap.scss';
