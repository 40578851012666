.t-height {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.active-tab{
    color:red;
    border-bottom: 1px solid red;
}
.profile{
  aspect-ratio: 1/1;
  width: 100px;
  border-radius: 50%;
}