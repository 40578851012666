.review {
    text-align: center;
    border: 0.3px solid #101057;
    margin: 10% 20%;
    border-radius: 0 50px 0 50px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
  }
  .carousel-control-prev-icon {
    background-image: url("../../assets/svg/prev.svg") !important;
  }
  
  .carousel-control-next-icon {
    background-image: url("../../assets/svg/next.svg") !important;
  }
  .carousel-indicators li{
      background: green !important
  }
  
  